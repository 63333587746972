#sidebar {
  --tw-translate-x: -100%;
}

#menu-close-icon {
  display: none;
}

#menu-open:checked~#sidebar {
  --tw-translate-x: 0;
}

#menu-open:checked~* #mobile-menu-button {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

#menu-open:checked~* #menu-open-icon {
  display: none;
}

#menu-open:checked~* #menu-close-icon {
  display: block;
}

@media (min-width: 768px) {
  #sidebar {
    --tw-translate-x: 0;
  }
}

.ant-modal-confirm-body {
  justify-content: center !important;
}

.ant-modal-confirm-paragraph {
  max-width: 100% !important;
}

#content {
  height: 100vh;
  overflow: auto;
}

.ant-select-clear .ant-input-suffix {
  color: red !important;
  font-size: 21px !important;
  margin-top: -10px !important;
  margin-right: 15px !important;
}

.anticon.anticon-close-circle {
  color: red !important;
  font-size: 21px !important;
}